import {Input} from '../InputBase/Input';
import styled from 'styled-components';
import {InputFieldTooltip} from '../Tooltip/InputTooltip';
import calendar from '../../assets/icons/calendar.svg';
import calendarDisabled from '../../assets/icons/calendar-disabled.svg';

const StyledDateInputField = styled.div`
    position: relative;
`;

const StyledInput = styled(Input)`
    &::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator {
        opacity: 0;
    }

    &::-webkit-date-and-time-value {
        text-align: left;
    }
    
    background-repeat: no-repeat, repeat;
    background-position: right ${({$hasTooltip}) => $hasTooltip ? "50px" : "13px"} top 50%, 0 0;
    
    &:not(:read-only) {
        background-image: url(${calendar});
    }
    
    &:read-only {
        background-image: url(${calendarDisabled});
    }
`;

export const DateInputField = ({id, label, required, readOnly, tooltip, ...props}) => {
    return (
        <StyledDateInputField>
            <StyledInput
                id={id}
                type="date"
                placeholder={label}
                defaultValue={props?.defaultValue}
                {...(props.register && props.register(props.name, {
                    required,
                    onBlur: () => { if(props.onFormFieldBlur) props.onFormFieldBlur() }
                }))}
                readOnly={readOnly}
                $hasTooltip={!!tooltip}
            />
            <InputFieldTooltip tooltip={tooltip} />
        </StyledDateInputField>
    );
}