import {createContext, useState, useContext} from "react";

export const FormContext = createContext(null);

export default function FormProvider({children}) {
    const [formValues, setFormValues] = useState({});
    const [stepIndex, setStepIndex] = useState(0);

    const updateFormValues = (data, questionnaireUUID) => {
        setFormValues((prevValues) => ({
            ...prevValues,
            [questionnaireUUID]: {
                ...prevValues[questionnaireUUID],
                ...data,
            }
        }));
    };

    const removeSpecificFormValues = (questionnaireUUID) => {
        setFormValues((prevValues) => {
            const updatedValues = { ...prevValues };
            if (updatedValues[questionnaireUUID]) {
                delete updatedValues[questionnaireUUID];
            }
            return updatedValues;
        });
    };

    return (
        <FormContext.Provider value={{
            formValues,
            updateFormValues,
            removeSpecificFormValues,
            stepIndex,
            setStepIndex,
        }}>
            {children}
        </FormContext.Provider>
    );
}

export const useFormContext = () => useContext(FormContext);

