import {Container} from "../components/Utils/Utils";
import PageHeader from "../components/PageHeader/PageHeader";
import {StepsContainer} from "../components/StepNavigation/StepsContainer";
import styled from "styled-components";
import FormFooter from "../components/Form/FormFooter"
import {Outlet, useLoaderData} from 'react-router-dom';
import {getData} from '../api/fetch';
import {AcceptedFormTargets} from '../constants/formTargets';
import qs from 'qs';

const StyledContainer = styled(Container)`
    @media screen and (max-width: 768px) {
        padding: 0;
    }
`;

const FormContainer = styled.div`
    position: relative;
    min-height: 250px;
    background: var(--color-white);
    box-shadow: var(--box-shadow);
    border-radius: 12px;
`;

export async function formLayoutLoader({request, params}) {
    const {formTarget} = params;

    if (!formTarget || !AcceptedFormTargets.includes(formTarget)) {
        throw Object.assign(new Error("Not Found"), { status: 404, key: "NotFound"});
    }

    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url?.search);
    const queryString = qs.stringify({
        studentId: searchParams.get("studentId") || undefined,
        mentorId: searchParams.get("mentorId") || undefined
    });

    return await getData(`form/${formTarget}${queryString ? `?${queryString}` : ''}`);
}

export const FormLayout = () => {
    const data = useLoaderData();

    return (
        <>
            <Container>
                <PageHeader />
            </Container>

            <StyledContainer>
                <FormContainer>
                    <StepsContainer />
                    <Outlet context={{data}} />
                </FormContainer>
            </StyledContainer>

            <Container>
                <FormFooter />
            </Container>
        </>
    );
}