import styled from 'styled-components';
import radioInactive from '../../assets/icons/radio.svg';
import radioInactiveDisabled from '../../assets/icons/radio-disabled.svg';
import radioChecked from '../../assets/icons/radio-checked.svg';
import radioCheckedDisabled from '../../assets/icons/radio-checked-disabled.svg';

export const Radio = styled.input.attrs({
    type: "radio"
})`
    appearance: none;
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    content: url(${radioInactive});

    &:not(:disabled):checked {
        content: url(${radioChecked});
    }
    
    &:disabled {
        content: url(${radioInactiveDisabled});
    }

    &:disabled:checked {
        content: url(${radioCheckedDisabled});
    }
`;