import styled from "styled-components";
import logo from "../../assets/owl.svg"
import {Heading1} from "../Heading/Heading";
import {useLoaderData} from "react-router-dom";

export const StyledPageHeader = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 40px 0;
    gap: 50px;

    @media screen and (max-width: 768px) {
        padding: 30px 0;
    }
`;

export const Logo = styled.img`
    margin-left: auto;
    width: 15%;
    max-width: 84px;
`;

export const PageHeader = () => {
    const data = useLoaderData();

    return (
        <StyledPageHeader>
            <Heading1>{data.title}</Heading1>
            <Logo src={data.image || logo} alt="" />
        </StyledPageHeader>
    );
}

export default PageHeader;