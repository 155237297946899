import styled, {css} from 'styled-components';
import {Spinner} from '../Spinner/Spinner';

const StyledButton = styled.button`
    background-color: ${({$variant}) => $variant === "secondary" ? "transparent" : "var(--color-light-blue)"};
    color: ${({$variant}) => $variant === "secondary" ? "var(--color-button-secondary-text)" : "var(--color-white)"};
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    cursor: pointer;
    padding: 10px 22px;
    font-weight: var(--fw-bold);
    font-size: var(--fs-button);
    text-decoration: none;
    text-align: center;
    border: none;
    appearance: none;
    min-height: 45px;
    
    &:disabled {
        opacity: .8;
        pointer-events: none;
    }
    
    @media (hover: hover) {
        :hover{
            ${({$variant}) => $variant === "secondary" ? css`
                color: var(--color-button-secondary-hover);
                
                svg {
                    path {
                        stroke-opacity: 0.6;
                    }
                }
            ` : css`
                background-color: var(--color-button-primary-hover);
            `};
        }
    }
`;

const StyledSpinner = styled(Spinner)`
    width: 23px;
    height: 23px;
`;

export const Button = ({className, children, variant = "primary", type = "button", loading, ...props}) => {
    return (
        <StyledButton
            className={className}
            type={type}
            $variant={variant}
            {...props}
        >
            {loading ? <StyledSpinner $white /> : children}
        </StyledButton>
    );
}